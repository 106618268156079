import { UploadOutlined } from '@ant-design/icons';
import { Button, Flex, message, Upload } from 'antd';
import { detectProvider, fieldParser } from '../../helpers';
import { ClearingAPI } from '../../../api/clearing-api/api';

export const UploadButton = () => {
  return (
    <Upload
      name="file"
      multiple={false}
      maxCount={1}
      beforeUpload={async (file) => {
        const fileString = await file.text();
        const { headers } = fieldParser(fileString);
        const provider = detectProvider(headers);
        if (!provider) {
          message.error('Не удалось определить провайдера');
          return false;
        }
      }}
      customRequest={async (options) => {
        const { file, onSuccess, onError } = options;
        if (typeof file !== 'string') {
          const fileString = await file.text();

          const { chanData, letsData, provider } = fieldParser(fileString);

          const props = {
            provider: provider,
            chanData: chanData,
            letsData: letsData,
          };

          ClearingAPI.postClearingData(props)
            .then((response) => {
              if (response.isSuccess) {
                onSuccess?.(response, file);
              } else {
                onError?.(Error(response.statusText));
              }
            })
            .catch((error) => {
              message.error(error);
            });
        } else {
          message.error('Ошибка загрузки файла');
        }
      }}
      onChange={(info) => {
        if (info.file.status !== 'uploading') {
          console.log('File info', info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      }}
    >
      <Flex vertical gap={10} align="center">
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
        {/* {selectedProvider && <Typography>{`Report type: ${selectedProvider}`}</Typography>} */}
      </Flex>
    </Upload>
  );
};
