export type TStatus = 'ALL' | 'ACTIVE' | 'INACTIVE';

export type TFileExtentions = 'json';

export const providers = ['CHANGELLY', 'LETS_EXCHANGE'] as const;
export const selectProviders = ['ALL', 'CHANGELLY', 'LETS_EXCHANGE'] as const;
export type TProviders = (typeof providers)[number];
export type SelectProviders = (typeof selectProviders)[number];

export const rateTypes = ['MARKET', 'FIXED'] as const;
export const selectRateTypes = ['ALL', 'MARKET', 'FIXED'] as const;
export type TRateTypes = (typeof rateTypes)[number];
export type SelectRateTypes = (typeof selectRateTypes)[number];

export type AtLeastOne<T> = {
  [K in keyof T]: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export const LetsExchangeColumns = [
  'affiliate_id',
  'transaction_id',
  'sent',
  'actual_sent_amount',
  'actual_sent_amount_(usdt)',
  'deposit_address',
  'got',
  'actual_got_amount',
  'withdrawal_address',
  'status',
  'rate',
  'date',
] as const;
export const ChangellyColumns = [
  'date',
  'transaction_id',
  'status',
  'currency_from',
  'amount_from',
  'currency_to',
  'amount_to',
  'earnings',
  'estimated_earnings_(btc)',
  'estimated_earnings_(usdt)',
] as const;

export const claimStatusesArr = [
  'NEW',
  'WAIT_DEPOSIT',
  'CONFIRM',
  'EXCHANGE',
  'SEND',
  'FINISH',
  'FAIL',
  'REFUND',
  'HOLD',
  'EXPIRE',
] as const;
export const selectClaimStatusesArr = [
  'ALL',
  'NEW',
  'WAIT_DEPOSIT',
  'CONFIRM',
  'EXCHANGE',
  'SEND',
  'FINISH',
  'FAIL',
  'REFUND',
  'HOLD',
  'EXPIRE',
] as const;
export type TClaimStatus = (typeof claimStatusesArr)[number];
export type SelectClaimStatus = (typeof selectClaimStatusesArr)[number];

// todo - remove this to external timeIntervalSelect
export const intervalsData = [
  {
    label: 'Off',
    value: '0',
  },
  {
    label: '2 сек',
    value: '2000',
  },
  {
    label: '5 сек',
    value: '5000',
  },
  {
    label: '10 сек',
    value: '10000',
  },
  {
    label: '20 сек',
    value: '20000',
  },
  {
    label: '30 сек',
    value: '20000',
  },
];

export type ParsedData = {
  chanData: ChangellyReport[] | null;
  letsData: LetsExchangeReport[] | null;
  headers: string[];
  provider: TProviders | null;
};

export type ChangellyReport = {
  date: string;
  transaction_id: string;
  status: string;
  currency_from: string;
  amount_from: string;
  currency_to: string;
  amount_to: string;
  earnings: string;
  'estimated_earnings_(btc)': string;
  'estimated_earnings_(usdt)': string;
};

export type LetsExchangeReport = {
  affiliate_id: string;
  transaction_id: string;
  sent: string;
  actual_sent_amount: string;
  'actual_sent_amount_(usdt)': string;
  deposit_address: string;
  got: string;
  actual_got_amount: string;
  withdrawal_address: string;
  status: string;
  rate: string;
  date: string;
};
