import { useState } from 'react';
import { Flex, Modal } from 'antd';
import { SelectStaticFilter } from '../../../../common/components/SelectStaticFilter';
import { providers, TProviders } from '../../../../common/types';
import { InputWrapper } from '../../../../common/components/InputWrapper';
import { UploadButton } from '../../../../common/components/Upload';

interface Props {
  open: boolean;
  onCancel: () => void;
}

const AddReportModal: React.FC<Props> = ({ open, onCancel }) => {
  // edit with file parcing result
  function handleProviderSelect(value: TProviders) {
    setSelectedProvider(value);
  }

  const [selectedProvider, setSelectedProvider] = useState<TProviders | undefined>(undefined);

  const providerData = providers.map((el) => ({ label: el, value: el }));

  return (
    <Modal
      open={open}
      title="Форма загрузки"
      onCancel={onCancel}
      destroyOnClose
      onOk={onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      style={{ overflow: 'auto' }}
    >
      <Flex vertical gap={20}>
        {selectedProvider && (
          <InputWrapper titleName="Провайдер">
            <SelectStaticFilter<TProviders>
              options={providerData}
              onSelect={handleProviderSelect}
              value={selectedProvider}
            />
          </InputWrapper>
        )}
        <UploadButton />
      </Flex>
    </Modal>
  );
};

export default AddReportModal;
