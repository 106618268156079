import { API } from '..';
import { getToken } from '../../context/AuthContextProvider';
import { getClearingDataParams, GetClearingDataResponse, postClearingReportParams } from './classes';

const PATHS = {
  CLEARING_STATS: '/api/clearing/stats',
  CLEARING_REPORT: '/api/clearing/report',
};

export const ClearingAPI = {
  getClearingData: async (props: getClearingDataParams) => {
    const token = getToken();
    return await API.get<GetClearingDataResponse>({
      url: PATHS.CLEARING_STATS,
      params: { ...props },
      headers: {
        'x-access-token': token,
      },
    });
  },
  postClearingData: async (props: postClearingReportParams) => {
    const token = getToken();
    return await API.post({
      url: PATHS.CLEARING_REPORT,
      headers: {
        'x-access-token': token,
      },
      data: {
        ...props,
      },
    });
  },
};
