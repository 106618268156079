import { makeAutoObservable } from 'mobx';

import { ClearingDataType } from '../../api/clearing-api/classes';
import { ClearingAPI } from '../../api/clearing-api/api';
import { TSelectDebounceValue } from '../../common/components/SelectDebounceFilter';
import { TProviders, TRateTypes } from '../../common/types';

export type getClearingParamsAndFIlters = {
  coinFromId?: TSelectDebounceValue;
  coinToId?: TSelectDebounceValue;
  networkFromId?: TSelectDebounceValue;
  networkToId?: TSelectDebounceValue;
  rateTypes?: TRateTypes;
  // status?: TClaimStatus;
  providerName?: TProviders;
  dateFrom: string;
  dateTo: string;
};

class ClearingVM {
  data: ClearingDataType[] = [];

  getClearingData = async (props: getClearingParamsAndFIlters) => {
    let transformedProps = {
      ...props,
      coinFromId: props?.coinFromId?.value,
      coinToId: props?.coinToId?.value,
      networkFromId: props?.networkFromId?.value,
      networkToId: props?.networkToId?.value,
      dateFrom: new Date(props.dateFrom).toISOString(),
      dateTo: new Date(props.dateTo).toISOString(),
    };

    const res = await ClearingAPI.getClearingData(transformedProps);
    if (res.status === 200) {
      if (res.data) {
        this.data = res.data.statsList;
      }
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default ClearingVM;
