import { useEffect, useRef } from 'react';

function useIntervalDataFetch(request: () => Promise<any>, refetchInterval: number | null) {
  const interval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (refetchInterval) {
      const intervalID = setInterval(() => {
        request();
      }, refetchInterval);
      interval.current = intervalID;
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [refetchInterval, request]);
}

export { useIntervalDataFetch as useDataRequest };
